import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

const images = [
  {
    url: process.env.PUBLIC_URL + '/code.jpg',
    title: 'Code',
    icon: CodeOutlinedIcon,
    route: 'https://github.com/majortriad'
  },
  {
    url: process.env.PUBLIC_URL + '/camera.jpg',
    title: 'Camera',
    icon: CameraAltOutlinedIcon,
    route: '/photos'
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 'calc(100vh - 64px)',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 'calc(50vh - ' + (56 / images.length) + 'px)',
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

export default function MainButtons() {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
      {images.map((image) => (

        <ImageButton
          focusRipple
          key={image.title}
          style={{
            width: (100 / images.length) + '%',
          }}

        >
          {image.route.startsWith('http') ?
            <a href={image.route}>
              <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <image.icon fontSize='large' />
              </Image>
            </a>
            :
            <Link to={image.route}>
              <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <image.icon fontSize='large' />
              </Image>
            </Link>
          }
        </ImageButton>
      ))}
    </Box>
  );
}

