import * as React from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Gallery from "react-photo-gallery";
import imagelist from './imagelist.json';

const publicUrl = process.env.PUBLIC_URL + "/photos/";
const thumbUrl = process.env.PUBLIC_URL + "/thumbs/"

const photoList = imagelist.photos.map((value) => ({
    full_src: publicUrl + value.src,
    //src: publicUrl + value.src,
    src: thumbUrl + value.src,
    height: value.height,
    width: value.width
}));

export default function PhotoList() {
    const [open, setOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState("");
    const handleOpen = React.useCallback((event: any, { photo, index }: any) => {
        setOpen(true);
        setCurrentImage(photo.full_src);
    }, []);
    const handleClose = () => setOpen(false);
    
    return (
        <div>
            <Gallery photos={photoList} onClick={handleOpen} />
            <Modal
                open={open}
                onClose={handleClose}
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            >
                <img src={currentImage} style={{maxHeight:"90%",maxWidth:"90%"}}/>
            </Modal>
        </div>
    );
}