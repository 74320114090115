import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppBar from './components/AppBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainButtons from './components/MainButtons';
import PhotoList from './components/PhotoList';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";


const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  },
});


function App() {
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <header>
          <AppBar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<MainButtons />} />
            <Route path="/photos" element={<PhotoList />} />
          </Routes>
        </main>
      </ThemeProvider>
    </Router>
  );
}

export default App;
